import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'

const ArticlePage = ({ pageContext }) => {
  const { category, articles } = pageContext

  return (
    <Layout seo={{ title: category }}>
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading'>
            <span className='underline'>{category}</span>
          </h1>
          <div className='articles'>
            {articles.map((article, index) => (
              <Link
                to={article.path}
                key={`${category}-article-${index}`}
                className='q-and-a-category__article-link'
              >
                {article.heading}
              </Link>
            ))}
          </div>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default ArticlePage
